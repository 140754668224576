<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-content>
                <div class="dialog-header">
                    <div class="dialog-title">Điều kiện lọc</div>
                </div>
                <div class="dialog-content">
                    <div class="page-list-body">
                        <div class="form-body" style="padding-top: 20px;">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-datepicker v-model="search.fromDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.search.fromDate.$error }">
                                        <label>Từ ngày</label>
                                        <span class="md-error" v-if="submitted && !$v.search.fromDate.required">Vui lòng chọn từ ngày</span>
                                    </md-datepicker>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-datepicker v-model="search.toDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.search.toDate.$error }">
                                        <label>Tới ngày</label>
                                        <span class="md-error" v-if="submitted && !$v.search.toDate.required">Vui lòng chọn tới ngày</span>
                                    </md-datepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="contractName" @md-selected="getContractSelected" :md-options="contracts" @md-changed="getContracts"  @md-opened="getContracts">
                                            <label>Hợp đồng</label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.contractName }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                        </md-autocomplete>
                                        <md-button @click="openContract()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                        </md-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </md-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="submit()">Search</md-button>
                <md-button class="md-primary" @click="showDialog = false">Close</md-button>
            </md-dialog-actions>
        </md-dialog>
        <contractList ref="contractList" @close="closeContract" />
    </div>
</template>

<script>
    import contractService from '../../../api/contractService';
    import messageBox from '../../../utils/messageBox'; 
    import common from '../../../mixins';
    import { required } from 'vuelidate/lib/validators';
    import contractList from '../../../components/popup/_ContractList.vue';

    export default {
        components: {
            contractList
        },
        data: () => ({
            showDialog: false,
            loadding: false, 
            search: { pageIndex: 1, pageSize: common.pageSize, fromDate: common.dateNow, toDate: common.dateNow, contractId: 0 },
            submitted: false,
            contractName: '',
            contracts: [],
        }),

        created() {
        },

        methods: { 
            //Contract
            closeContract(item){
                this.contractName = item.contractName;
                this.search.contractId = item.id;
                this.$refs.contractList.close();
            },

            getContractSelected(val){
                this.contractName = val.contractName;
                this.search.contractId = val.id;
            },

            openContract(){
                this.$refs.contractList.open();
            },

            getContracts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, keyword: searchTerm };
                contractService.getContracts(search).then((response) => {
                    if(response.statusCode == 200){
                        this.contracts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.$emit('close', this.search);
                this.showDialog = false;
            },

            open(){
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },
        },
        watch: { 
            contractName: function (val) { 
                if(val == ''){
                    this.search.contractId = 0;
                }
            }
        },
        validations: {
            search: {
                fromDate: { required },
                toDate: { required },
            }
        }
    }
</script>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        width: 700px;
        height: 200px;
        max-width: 700px;
        max-height: 200px;
    }
    .dialog-content {
        height: 200px;
    }
</style>